@keyframes rainbow{
    100%,0%{
        color: rgb(255,0,0);
    }
    8%{
        color: rgb(255,127,0);
    }
    16%{
        color: rgb(255,255,0);
    }
    25%{
        color: rgb(127,255,0);
    }
    33%{
        color: rgb(0,255,0);
    }
    41%{
        color: rgb(0,255,127);
    }
    50%{
        color: rgb(0,255,255);
    }
    58%{
        color: rgb(0,127,255);
    }
    66%{
        color: rgb(0,0,255);
    }
    75%{
        color: rgb(127,0,255);
    }
    83%{
        color: rgb(255,0,255);
    }
    91%{
        color: rgb(255,0,127);
    }
}

.play {
    font-size: 30px;
    margin: auto;
    position: absolute;
    background: white;
    font-family: sans-serif;
    top: 0; left: 0; bottom: 0; right: 0;
}

.play.ready {
    animation: rainbow 2.5s linear;
    animation-iteration-count: infinite;
}
