@keyframes fly {
    from {
        left: -100%;
    }

    to {
        left: 100%;
    }
}

.dove {
    position: absolute;
    transform: rotateY(180deg);
    top: 5%;
    left: -100%;
    animation-duration: 4s;
    animation-name: fly;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
